.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: #282c34;
}
.App-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-section h1 {
  font-weight: bolder;
  font-size: 200px;
  margin: 0;
}

.App-section p strong{
  font-weight: bolder;
}

.App-section p.days-ext span{
  font-weight: bolder;
  font-family: monospace;
  color: yellow;
  background-color: blue;
  padding: 10px;
  margin-right: 20px;
}

.App-link {
  color: #61dafb;
}

header {
  font-size: 12px;
  text-align: right;
  padding: 10px;
  color:gray;
}

header p {
  margin: 0;
}

footer.copyright {
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: gray;
  text-align: left;
  padding: 3px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
